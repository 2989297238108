*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  font-family: inherit;
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-size: 1.6rem;
}

button {
  cursor: pointer;
  transition: all 0.3s ease;
}

#root {
  position: fixed;
  background-image: linear-gradient(
      180deg,
      rgba(15, 78, 255, 0.28),
      rgba(253, 50, 50, 0.69)
    ),
    url(assets/images/bg.jpg);
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

#app-container {
  display: grid;
  grid-template-columns: repeat(2, 50vw);

  @media screen and (max-width: 800px) {
    grid-template-columns: 55vw 45vw;
  }
}
