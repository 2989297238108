.Menu {
  position: fixed;
  z-index: -100;
  opacity: 0;
  left: 50%;
  top: 50%;
  width: 25vw;
  border-radius: 2rem;
  transform: translate(-50%, 500%);
  padding: 5rem 4rem 6rem;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: 0.5s ease-out;
  border: 0.1rem solid #000;
  background-image: linear-gradient(
      180deg,
      rgba(#e67e22, 0.9),
      rgba(#5d2e05, 0.95)
    ),
    url(../../assets/images/menu-bg.png);

  @media only screen and (max-width: 1400px) {
    width: 30vw;
  }

  @media only screen and (max-width: 1175px) {
    width: 35vw;
  }

  @media only screen and (max-width: 950px) {
    width: 45vw;
  }

  @media only screen and (max-width: 800px) {
    width: 50vw;
  }

  @media only screen and (max-width: 600px) {
    width: 60vw;
  }

  @media screen and (max-width: 400px) {
    width: 80vw;
    padding: 3rem 2rem 4rem;
  }
}

.Open {
  transform: translate(-50%, -50%);
  z-index: 1000;
  opacity: 1;
}

.Heading {
  color: #fff;
  text-align: center;
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  font-size: 6rem;
  margin-bottom: 2rem;
  text-transform: uppercase;

  @media only screen and (max-width: 1175px) {
    font-size: 5rem;
  }

  @media only screen and (max-width: 700px) {
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
}

.Score {
  color: #fff;
  text-align: center;
  font-weight: 300;
  font-size: 3.5rem;

  &__Label {
    font-size: 2rem;
  }
}

.Btn {
  display: block;
  background-color: #0bbfe6;
  color: #055466;
  outline: none;
  font-family: inherit;
  font-size: 2.5rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  width: 80%;
  border: 0.25rem solid #0889a6;
  letter-spacing: 0.1rem;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2rem;

  &:first-of-type {
    margin-top: 2rem;
  }

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &:hover {
    background-color: #0cc8f2;
    color: #086479;
  }

  @media only screen and (max-width: 1175px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 700px) {
    font-size: 1.75rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 300px) {
    font-size: 1.2rem;
    border-radius: 1rem;
    width: 95%;
  }
}

.ControlsImg {
  width: 100%;
  margin-left: 1.5rem;

  @media only screen and (max-width: 1500px) {
    margin-left: 1rem;
  }
}
