.GameGrid {
  width: 1000px;
  height: 75vh;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.55);
  margin: 30px;
  padding: 10px;
  display: grid;
  justify-self: end;
  width: 53vh;
  max-width: 45vw;
  height: 80vh;
  max-height: 90vw;
}

.Blocks {
  width: 100%;
  height: 100%;
  &__unfilled {
    // Available grid spaces.
    border: 0.5px solid rgba($color: #363636, $alpha: 0.15);
  }

  &__filled {
    // Blocks which are currently on the grid.
    border: 0.5px solid black;
    display: block;
  }
}
