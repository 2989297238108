.Controls {
  display: flex;
  justify-content: center;
  grid-column: 1/-1;
  margin-top: -1.5rem;

}

.Buttons {
  background-color: transparent;
  border-color: transparent;
  color: #ffcf00;
  font-size: 8rem;
  outline: none;
  padding: 1rem;

  &:hover {
    color: #ffbd06;
  }

  &:active {
    color: #dba100;
    transform: translate(1px, 1px);
  }


  @media screen and (max-width: 900px) {
    font-size: 7rem;
  }

  @media screen and (max-width: 700px) {
    font-size: 6rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 5rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 350px) {
    font-size: 3rem;
  }
}

.PauseBtn {
  font-size: 4rem;
  position: fixed;
  top: 3rem;
  left: 50%;

  @media screen and (max-width: 600px) {
    left: unset;
    top: 1%;
    right: 1%;
  }

  @media screen and (max-width: 500px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 2rem;
    left: 0;
    top: 0;
    right: unset;
  }
}
